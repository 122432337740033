const taskData = {
  "65ea0f0ed830e61354340ea5": {
    //Panic Attack and Panic Disorder
    1: { 2: 1 },
    2: { 2: 1 },
    4: { null: 1 },
    5: { 2: 3 },
    7: { 2: 3 },
    11: { null: 2 },
    12: { 2: 3 },
    14: { 2: 3 },
    15: { null: 3 },
  },
  "65eb9ed1d830e61354341864": {
    //Post-Traumatic Stress Disorder and Acute Stress Disorder
    1: { 2: 8 },
    12: { 1: 1, 2: 1 },
    14: { null: 1 },
    17: { 2: 2 },
    18: { null: 2 },
    30: { 2: 3 },
    31: { null: 3 },
    39: { 1: 4, 2: 4 },
    42: { 2: 5 },
    43: { 2: 5 },
    47: { 2: 5 },
    49: { 2: 5 },
    52: { 1: 5, 2: 5 },
    74: { 1: 6, 2: 6 },
    75: { 2: 7 },
    76: { 2: 7 },
    77: { 2: 7 },
    81: { 2: 7 },
    83: { 1: 7, 2: 7 },
  },
  "661c785bf7c44e495a74611b": {
    //Eating Disorders
    1: { 2: 6 },
    7: { null: 1 },
    17: { 1: 2, 2: 2 },
    18: { 1: 3, 2: 3 },
    19: { 1: 3, 2: 3 },
    20: { 1: 4, 2: 4 },
    22: { 1: 5, 2: 5 },
  },
  "65f85edda87a3ccb451517f0": {
    //Eating Disorders - Anorexia
    32: { 1: 1, 2: 1 },
    35: { 1: 2, 2: 2 },
  },
  "65f86529a87a3ccb45151863": {
    //Eating Disorders - Avoidant/Restrictive Food Intake Disorder
    1: { null: 1 },
    2: { null: 2 },
    3: { 2: 3 },
    4: { 2: 3 },
    7: { 2: 3 },
    8: { 2: 3 },
  },
  "661d941af7c44e495a746488": {
    //Specific Phobia
    9: { 2: 1 },
    10: { null: 1 },
    11: { 2: 2 },
    15: { 2: 2 },
    16: { 2: 2 },
    18: { 2: 2 },
    19: { 2: 2 },
    23: { 2: 2 },
    25: { 1: 2, 2: 2 },
    28: { null: 7 },
    29: { 2: 3 },
    33: { 2: 3 },
    34: { 2: 3 },
    36: { 2: 3 },
    37: { 2: 3 },
    41: { 2: 3 },
    43: { 1: 3, 2: 3 },
    46: { null: 8 },
    47: { 2: 4 },
    51: { 2: 4 },
    52: { 2: 4 },
    54: { 2: 4 },
    55: { 2: 4 },
    59: { 2: 4 },
    61: { 1: 4, 2: 4 },
    64: { null: 9 },
    65: { 2: 5 },
    69: { 2: 5 },
    70: { 2: 5 },
    72: { 2: 5 },
    73: { 2: 5 },
    77: { 2: 5 },
    79: { 1: 5, 2: 5 },
    82: { null: 10 },
    83: { 2: 6 },
    87: { 2: 6 },
    88: { 2: 6 },
    90: { 2: 6 },
    91: { 2: 6 },
    95: { 2: 6 },
    97: { 1: 6, 2: 6 },
    100: { null: 11 },
  },
  "661ea0f0f7c44e495a7465d5": {
    //Substance Use Disorder
    1: { 2: 1 },
    3: { 2: 2 },
    4: { null: 3 },
  },
  "661ed84ef7c44e495a746897": {
    //Cannabis Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    67: { null: 5 },
  },
  "661ee120f7c44e495a7469a2": {
    //Sedative, Hypnotic or Anxiolytic Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    67: { null: 5 },
  },
  "661ee455f7c44e495a746aaa": {
    //Opioid Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    67: { null: 5 },
  },
  "661ee75ff7c44e495a746bb2": {
    //Stimulant Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    67: { null: 5 },
  },
  "661ef7adf7c44e495a7471b5": {
    //Caffeine Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    67: { null: 5 },
  },
  "661efa56f7c44e495a7472bc": {
    //Tobacco Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    67: { null: 5 },
  },
  "661eeaf5f7c44e495a746db9": {
    //Hallucinogen- Phencyclidine Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    66: { null: 4 },
  },
  "661eedc7f7c44e495a746eb8": {
    //Hallucinogen- Other Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    66: { null: 4 },
  },
  "661ef01df7c44e495a746fb7": {
    //Inhalant Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    66: { null: 4 },
  },
  "661ef4bff7c44e495a7470b6": {
    //Other Substance Usage
    22: { 1: 1, 2: 1 },
    45: { 1: 2, 2: 2 },
    47: { 2: 4 },
    65: { 1: 3, 2: 3 },
    66: { null: 4 },
  },
  "6614873cf7c44e495a745f32": {
    //Alcohol Use Disorder (Current/Early Remission)
    27: { 2: 1 },
    34: { 2: 1 },
    57: { 1: 1, 2: 1 },
  },
  "65ea2943d830e61354341072": {
    //Generalized Anxiety Disorder
    1: { 2: 2 },
    6: { 2: 2 },
    7: { 2: 2 },
    11: { null: 1 },
    12: { 2: 2 },
    16: { 2: 2 },
    18: { 1: 2, 2: 2 },
  },
  "65e90386d830e6135434032a": {
    //Social Anxiety Disorder
    1: { 2: 1 },
    3: { 2: 1 },
    5: { 2: 1 },
    6: { 2: 1 },
    7: { 2: 1 },
    8: { 2: 1 },
    10: { 2: 1 },
    14: { 2: 1 },
    16: { 1: 1, 2: 1 },
  },
  "65ea32f9d830e61354341103": {
    //Illness Anxiety Disorder
    1: { 2: 2 },
    4: { 2: 2 },
    8: { 2: 2 },
    13: { 1: 1, 2: 1 },
    14: { 2: 2 },
    15: { null: 2 },
  },
  "65e9f524d830e61354340d7f": {
    //Obsessive-Compulsive Disorder
    7: { 2: 1 },
    9: { 1: 2, 2: 2 },
    12: { 2: 3 },
    20: { 1: 4, 2: 4 },
  },
  "6601a86ba87a3ccb451585ac": {
    //Psychosis Screen
    113: { 2: 1 },
    120: { null: 1 },
  },
  "66154bd7f7c44e495a746011": {
    //Attention-Deficit/Hyperactivity Disorder
    1: { 2: 3 },
    19: { 2: 1 },
    24: { null: 2 },
    25: { 2: 3 },
    27: { 1: 3, 2: 3 },
  },
  "65f0906ad830e61354342be2": {
    //Alcohol Use Disorder (Sustained Remission)
    1: { 2: 2 },
    9: { 2: 2 },
    28: { null: 1 },
    29: { 2: 2 },
    30: { null: 2 },
  },
  "65f0c881d830e61354342d7f": {
    //Excoriation (Skin-Picking) Disorder
    1: { 2: 1 },
    9: { 2: 1 },
    11: { 2: 1 },
    15: { 2: 1 },
    17: { 2: 1 },
    18: { 1: 1, 2: 1 },
  },
  "65f317d4e60d46c42dfce414": {
    //Gambling Disorder
    4: { null: 1 },
    23: { null: 2 },
    25: { 2: 3 },
    38: { 1: 3, 2: 3 },
  },
  "6626aed7f7c44e495a7476b3": {
    //Hoarding Disorder
    1: { 2: 1 },
    4: { 2: 1 },
    6: { 2: 1 },
    9: { 2: 1 },
    16: { 2: 1 },
    19: { 1: 1, 2: 1, 3: 1 },
  },
  "65f1b2ccd830e613543434c1": {
    //Insomnia Disorder
    1: { 2: 2 },
    2: { null: 1 },
    3: { 1: 2, 2: 2, 3: 2 },
    5: { 2: 2 },
    6: { 2: 2 },
    12: { 2: 2 },
    13: { 2: 2 },
    15: { 2: 2 },
    20: { null: 2 },
  },
  "65f1c8b6d830e61354344ed9": {
    //Selective Mutism
    1: { 2: 1 },
    5: { 2: 1 },
    6: { 2: 1 },
    7: { 2: 1 },
    8: { 1: 1, 2: 1 },
  },
  "65f1d297d830e61354345159": {
    //Separation Anxiety Disorder
    1: { 2: 2 },
    19: { 1: 1, 2: 1 },
    20: { 2: 2 },
    21: { 2: 2 },
    23: { 1: 2, 2: 2 },
  },
  "6626df45f7c44e495a7476ff": {
    //Somatic Symptom Disorder
    1: { 2: 2 },
    13: { 1: 1, 2: 1 },
    14: { 1: 2 },
    15: { 1: 2, 2: 2 },
  },
  "65f1db92d830e613543451ee": {
    //Trichotillomania
    1: { 1: 1 },
    8: { 1: 1 },
    10: { 1: 1 },
    14: { 1: 1 },
  },
  "65dcaf22d830e61354335e46": {
    //AUDIT
    10: { 1: 1, 2: 1, 3: 1 },
  },
  "65e6022cd830e61354338abd": {
    //AVHRS
    40: { 1: 1, 2: 1 },
  },
  "65dcb5bfd830e61354335e8b": {
    //BSL-23
    35: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "65dccb12d830e61354335f91": {
    //DERS
    36: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "65dcdb63d830e61354336090": {
    //DES-11
    28: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1 },
  },
  "6626ad79f7c44e495a747663": {
    //DUDIT
    12: { 1: 1, 2: 1, 3: 1 },
  },
  "65e0f3bbd830e613543377b5": {
    //GAD-7
    7: { 1: 1, 2: 1, 3: 1, 4: 1 },
  },
  "65e1f046d830e613543387e4": {
    //HAI-SF
    18: { 1: 1, 2: 1, 3: 1, 4: 1 },
  },
  "65e21578d830e61354338a89": {
    //ISI
    7: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "65e206c7d830e61354338913": {
    //MC-SDS
    33: { 1: 1, 2: 1 },
  },
  "65e20a86d830e613543389fa": {
    //PCL-5
    20: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "65ddfd50d830e6135433633b": {
    //PHQ-9
    10: { 1: 1, 2: 1, 3: 1, 4: 1 },
  },
  "65e9e1b8d830e61354340c5d": {
    //PSWQ
    16: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "660c0ed9f7c44e495a7459a4": {
    //R-GPTS
    18: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "65dce8a4d830e613543361ff": {
    //SADS
    28: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "65dded0ed830e613543362bb": {
    //SMSP
    11: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "65dcf53dd830e61354336272": {
    //SMPD
    10: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "65ddefa8d830e6135433630b": {
    //SWLS
    5: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1 },
  },
  "65de2f65d830e613543363ca": {
    //Y-BOCS
    10: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1 },
  },
  "65de124ed830e6135433637a": {
    //YMRS
    11: { 1: 1, 2: 1, 3: 1, 4: 1, 5: 1 },
  },
  "65f21f42d830e613543452ea": {
    //NSSI-AT
    23: { 1: 1, 2: 1, 3: 1, 4: 1 },
    24: { 1: 2, 2: 2, 3: 2 },
    31: { null: 3 },
    33: { null: 4 },
    57: { null: 5 },
    65: { null: 6 },
    69: { null: 7 },
    70: { null: 8 },
    72: { null: 9 },
    74: { null: 10 },
    76: { null: 11 },
    78: { null: 12 },
    80: { null: 13 },
    82: { null: 14 },
    84: { null: 15 },
    86: { null: 16 },
    88: { null: 17 },
    90: { null: 18 },
    92: { null: 19 },
    94: { null: 20 },
    98: { null: 21 },
    99: { null: 22 },
    100: { null: 23 },
    101: { null: 24 },
    102: { null: 25 },
    103: { null: 26 },
    104: { null: 27 },
    105: { null: 28 },
    106: { null: 29 },
    107: { null: 30 },
    108: { null: 31 },
    109: { null: 32 },
    110: { null: 33 },
    111: { null: 34 },
  },
  "65f1b63ed830e6135434376c": {
    //PID-5
    220: { 1: 1, 2: 1, 3: 1, 4: 1 },
  },
  "65ecc89dd830e61354341fe4": {
    //MDD
    5: {
      2: 1,
    },
    9: {
      null: 1,
    },
    32: {
      2: 2,
    },
    37: {
      null: 2,
    },
    50: {
      2: 3,
    },
    55: {
      1: 3,
      2: 3,
    },
  },
  "65e908bdd830e613543404c3": {
    //PDD
    1: {
      2: 1,
    },
    3: {
      null: 1,
    },
    4: {
      2: 2,
    },
    8: {
      1: 2,
      2: 2,
    },
  },
  "65ea1ac6d830e61354341035": {
    //Agoraphobia
    1: {
      2: 1,
    },
    4: {
      null: 1,
    },
    5: {
      2: 1,
    },
    6: {
      null: 2,
    },
    7: { 2: 3 },
    9: { 2: 3 },
    11: { 2: 3 },
    15: {
      1: 3,
      2: 3,
    },
  },
  "65e9d96ad830e61354340b1b": {
    //Risk Assessment
    68: {
      2: 1,
    },
    79: {
      2: 1,
    },
    83: {
      null: 1,
    },
  },
  "661cf862f7c44e495a7461b8": {
    //Body Dysmorphic Disorder
    1: {
      2: 1,
    },
    11: {
      1: 1,
      2: 1,
      3: 1,
      4: 1,
    },
  },
  "65ef3611d830e613543421e8": {
    //Depersonalization/Derealization Disorder
    8: {
      1: 1,
      2: 1,
    },
    14: {
      2: 2,
    },
    15: {
      2: 2,
    },
    21: {
      2: 2,
    },
    22: {
      1: 2,
      2: 2,
    },
  },
  "65ebb7f6d830e61354341998": {
    //Bipolar
    9: {
      2: 1,
    },
    17: {
      2: 5,
    },
    37: {
      1: 2,
      2: 2,
    },
    39: {
      1: 3,
      2: 3,
    },
    42: {
      2: 5,
    },
    45: {
      1: 4,
      2: 4,
    },
    50: {
      2: 5,
    },
    54: {
      1: 5,
      2: 5,
    },
  },
  "65f86f2ea87a3ccb4515188f": {
    //Eating Disorder - Binge Eating Disorder
    3: {
      1: 1,
      2: 1,
    },
    4: {
      null: 2,
    },
    5: {
      2: 3,
    },
    9: {
      null: 3,
    },
  },
  "6625a2e6f7c44e495a7475c7": {
    //Eating Disorder - Bulimia Nervosa
    3: {
      1: 1,
      2: 1,
    },
    15: {
      1: 2,
    },
    18: {
      null: 2,
    },
  },
  "65f0b158d830e61354342c6e": {
    //Borderline Personality Disorder
    63: {
      2: 1,
    },
    67: {
      1: 1,
      2: 1,
    },
  },
  "663a17a947f86af6571fd513": {
    //Bipolar Disorders Specifiers
    1: {
      null: 1,
    },
    2: {
      null: 2,
    },
    5: {
      1: 3,
      2: 3,
    },
    7: {
      null: 4,
    },
    10: {
      1: 5,
      2: 5,
    },
    12: {
      1: 6,
      2: 6,
    },
    13: {
      1: 7,
      2: 7,
    },
  },
  "663a1e4347f86af6571fd570": {
    //Depressive Disorders Specifiers
    1: {
      null: 1,
    },
    2: {
      null: 2,
    },
    5: {
      1: 3,
      2: 3,
    },
    8: {
      null: 4,
    },
    11: {
      1: 5,
      2: 5,
    },
    12: {
      null: 6,
    },
    13: {
      1: 7,
      2: 7,
    },
  },

  // Additional forms and questions can be added as necessary.
};

export { taskData }; // Exporting taskData for usage throughout the application.

// Each property of the object corresponds to a form ID.
// 'sampleFormId': {  // A form with a unique ID
//     1 : {  // Question order 1 - Single choice question
//         1: 2,  // Option order 1 maps to task number 2
//     },
//     2: {  // Question index 2 - Multiple-choice question
//     },
//         null: 3  // A 'null' value represents any or no selected option, mapping to task number 3
// },
