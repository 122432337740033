import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axiosService';
import './detailsForm.css'; 

const PatientDetailsForm = () => {
  const { aid } = useParams();
  const [yearOfBirth, setYearOfBirth] = useState(''); // Updated state variable
  const [gender, setGender] = useState('');
  const [province, setProvince] = useState('');
  const navigate = useNavigate();

  const provinces = [
    'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador',
    'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Nunavut',
    'Northwest Territories', 'Yukon'
  ];

  const genders = ['Male', 'Female', 'Other', 'Prefer not to say'];

  // Generate years for the dropdown
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1899 }, (v, k) => currentYear - k);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!yearOfBirth || !gender || !province) {
      alert('Please fill all the fields');
      return;
    }

    try {
      await axiosInstance.put(`/api/forms/assessment/${aid}`, {
        userDetails: {
          yearOfBirth, 
          gender,
          province
        }
      });
      navigate(`/form/${aid}`);
    } catch (error) {
      console.error('Error saving patient details:', error);
    }
  };

  return (
    <>
      <p className="form-message">
        This test is meant to help understand your mental health. It will take around 45 minutes to an hour. Please answer all the questions carefully and honestly. Your answers will help us give you the best possible assessment.
      </p>
      <form onSubmit={handleSubmit} className="form-container">
        <p style={{ fontWeight: 'bold' }}>Enter the following details:</p>
        <div className="form-field">
          <label className="form-label">Year of Birth:</label>
          <select value={yearOfBirth} onChange={(e) => setYearOfBirth(e.target.value)} required className="form-select">
            <option value="">Select...</option>
            {years.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="form-field">
          <label className="form-label">Gender:</label>
          <select value={gender} onChange={(e) => setGender(e.target.value)} required className="form-select">
            <option value="">Select...</option>
            {genders.map((genderOption) => (
              <option key={genderOption} value={genderOption}>{genderOption}</option>
            ))}
          </select>
        </div>
        <div className="form-field">
          <label className="form-label">Province:</label>
          <select value={province} onChange={(e) => setProvince(e.target.value)} required className="form-select">
            <option value="">Select...</option>
            {provinces.map((provinceOption) => (
              <option key={provinceOption} value={provinceOption}>{provinceOption}</option>
            ))}
          </select>
        </div>
        <button type="submit" className="form-button">Take me to the assessment</button>
      </form>
    </>
  );
};

export default PatientDetailsForm;
