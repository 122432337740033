 import axiosInstance from '../../../api/axiosService';
 import getTaskNum from './getTaskNum';

  
  const taskMapping = async (formId, questionOrder, selectedOptionValue, responseId) => {

    const taskNum = getTaskNum(formId, questionOrder, selectedOptionValue);
    if (taskNum === 0) {
      return null
    } else {
    try {
    const form = await axiosInstance.get(`/api/forms/${formId}`);
    const formTier = form.data.tier; 
    const response = await axiosInstance.get(`/api/response/evaluate/${responseId}/${taskNum}`);
    const jumpToValue = (formTier === "individual") ? 0 : response.data.jumpTo;
      return jumpToValue;
    } catch (error) {
      return null; 
    }
}
  };


  export default taskMapping;