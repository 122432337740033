import React, { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import axiosInstance from '../../api/axiosService';
import './form.css';
import { Button } from 'react-bootstrap';

const FormSubmit = () => {
 const { aid } = useParams();
 const [userType, setUserType] = useState('');

 useEffect(() => {
  const fetchSummaries = async () => {
     try {
       const assessmentData = await axiosInstance.get(`/api/forms/assessment/${aid}`);
       setUserType(assessmentData.data.patientId.type); 

       if (!assessmentData.data.shortSummary || !assessmentData.data.longSummary) {
          axiosInstance.get(`/api/summary/${aid}/shortSummary`, {
          timeout: 20 * 60 * 1000, 
         });
          axiosInstance.get(`/api/summary/${aid}/longSummary`, {
          timeout: 20 * 60 * 1000, 
         });
       }
     } catch (error) {
       console.error('Error fetching summaries:', error);
     }
  };
  
  fetchSummaries();
 }, [aid]);

 const goToDashboard = () => {
  window.location.href = 'https://user-stage.cliniscripts.com/dashboard'; 
 };

 return (
    <div className="container">
      <h1>Submission Received</h1>
      <h3>Thank you for completing the assessment.</h3>
      <h1>Your report will be ready shortly.</h1>
      {userType === 'end-user' && (
        <Button onClick={goToDashboard}
        style={{marginTop: '20px', width: '200px', height: '50px', fontSize: '20px'}}
        >Go to Dashboard</Button>
      )}
    </div>
 );
};

export default FormSubmit;
