import { taskData } from './taskData';

const getTaskNum = (formId, questionOrder, selectedOptionValue) => {
    const form = taskData[formId];
    if (!form){
      return 0;
    } 
    
    const question = form[questionOrder];
    if (!question) {
      return 0;
    }
  
    const taskNum = question[selectedOptionValue] || question[null] || 0;
    return taskNum;
  };
  export default getTaskNum;