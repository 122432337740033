import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../api/axiosService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import taskMapping from "./taskMapping/taskMapping";
import getTaskNum from "./taskMapping/getTaskNum";
import socket from "./config/socketConfig";
import RecordRTC from "recordrtc";
import "bootstrap/dist/css/bootstrap.min.css";
import "./form.css";

const Formfile = () => {
  //State variables
  const [formIds, setFormIds] = useState([]);
  const [form, setForm] = useState(null);

  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responseId, setResponseId] = useState(null);

  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [formResponses, setFormResponses] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [formDataFetched, setFormDataFetched] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [assessmentData, setAssessmentData] = useState(null);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const recorderRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const textAreaRef = useRef(null);

  const navigate = useNavigate();
  const { aid } = useParams();
  const isButtonVisible = true;

  useEffect(() => {
    console.log({ assessmentData });
  }, [assessmentData]);

  const fetchAssessmentData = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/forms/assessment/${aid}`);
      console.log({ data });

      setAssessmentData(data);
      setCurrentFormIndex(data.progress.currentFormIndex);
      setCurrentQuestionIndex(data.progress.currentQuestionIndex);
      setResponseId(data.progress.responseId);
      setFormIds(data.formIds || []);

      return data;
    } catch (error) {
      console.error("Failed to fetch assessment data:", error);
    }
  };

  //Fetch the assessment data from the server
  useEffect(() => {
    const initFetchData = async () => {
      try {
        const data = await fetchAssessmentData();

        if (!data || data.progress.status === "completed") {
          navigate(`/assessmentStatus/${aid}`);
        } else if (!data.userDetails) {
          if (data.patientId.type === "endUser") {
            navigate(`/endUserDetails/${aid}`);
          } else if (data.patientId.type === "patient") {
            navigate(`/patientDetails/${aid}`);
          }
        } else {
          setFormIds(data.formIds || []);
        }
      } catch (error) {
        console.error("Failed to fetch assessment data:", error);
      }
    };
    if (aid) {
      initFetchData();
    }
  }, []);

  // useEffect(() => {
  //   const updateAssessmentData = async () => {
  //     try {
  //       const { data } = await axiosInstance.get(`/api/forms/assessment/${aid}`);
  //       console.log({ data });
  //       setAssessmentData(data);
  //       setResponseId(data.progress.responseId);
  //     } catch (error) {
  //       console.error("Failed to fetch assessment data:", error);
  //     }
  //   };

  //   updateAssessmentData();
  // }, [currentFormIndex, currentQuestionIndex]);

  // useEffect(() => {
  //   const fetchAssessmentData = async () => {
  //     const { data } = await axiosInstance.get(`/api/forms/assessment/${aid}`);
  //     console.log({ data });
  // if (!data || data.progress.status === "completed") {
  //   navigate(`/assessmentStatus/${aid}`);
  // } else if (!data.userDetails) {
  //   if (data.patientId.type === "endUser") {
  //     navigate(`/endUserDetails/${aid}`);
  //   } else if (data.patientId.type === "patient") {
  //     navigate(`/patientDetails/${aid}`);
  //   }
  // } else {
  //   setFormIds(data.formIds || []);
  // }
  //   };
  //   fetchAssessmentData();
  // }, []);

  // Define a function to calculate the progress percentage
  const calculateProgressPercentage = (assessmentData) => {
    const totalForms = assessmentData.formIds.length;
    const currentProgress = assessmentData.progress.currentFormIndex;
    return (currentProgress / totalForms) * 100;
  };

  // Use a useEffect hook to update the progress percentage
  useEffect(() => {
    const updateProgress = async () => {
      try {
        if (assessmentData) {
          const newProgressPercentage = calculateProgressPercentage(assessmentData);
          setProgressPercentage(newProgressPercentage);
        }
      } catch (error) {
        console.error("Failed to update progress:", error);
      }
    };

    updateProgress();
  }, [aid, formIds, currentFormIndex]);

  useEffect(() => {
    if (isConnected) {
      socket.emit("start");
    }
  }, [isConnected]);

  //useEffect to handle the socket connection
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    socket.on("transcript", (transcript) => {
      setForm((prevForm) => {
        const updatedQuestions = prevForm.questions.map((question) => {
          if (
            question.id === prevForm.questions[currentQuestionIndex].id &&
            question.type === "text"
          ) {
            return {
              ...question,
              textAnswer: question.textAnswer + transcript,
            };
          }
          return question;
        });
        return { ...prevForm, questions: updatedQuestions };
      });
      adjustTextAreaHeight();
    });

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("transcript");
    };
  }, [currentQuestionIndex]);

  //Function to handle the recording
  const handleRecording = async () => {
    if (!isRecording) {
      socket.connect();
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      recorderRef.current = new RecordRTC(mediaStreamRef.current, {
        type: "audio",
        disableLogs: true,
        mimeType: "audio/wav",
        sampleRate: 44100,
        desiredSampRate: 16000,
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
        timeSlice: 1000,
        bufferSize: 4096,
        ondataavailable: function (blob) {
          if (blob.size > 0) {
            socket.emit("audio", blob);
          } else {
            let silentBlob = new Blob([new ArrayBuffer((16000 * 2 * 15) / 8)], {
              type: "audio/wav",
            });
            socket.emit("audio", silentBlob);
          }
        },
      });
      await recorderRef.current.startRecording();
      setIsRecording(true);
    } else {
      handleStop();
    }
  };

  //Function to stop the recording
  const handleStop = async () => {
    if (isRecording) {
      socket.disconnect();
      await recorderRef.current?.stopRecording();
      await recorderRef.current?.destroy();
      mediaStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      setIsRecording(false);
    }
  };

  //Function to adjust the height of the text area
  const adjustTextAreaHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "inherit";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight * 1.2}px`;
    }
  };

  //To fetch the form data from the server
  const fetchFormData = async () => {
    setIsTransitioning(true);
    try {
      const { data } = await axiosInstance.get(`/api/forms/${formIds[currentFormIndex]}`);
      console.log({ data });

      const initialForm = {
        title: data.formName,
        formId: data._id,
        description: data.description,
        questions: mapQuestions(data.questions),
      };
      setForm(initialForm);
      setFormResponses(new Array(initialForm.questions.length).fill(""));
      setFormDataFetched(true);
    } catch (error) {
      console.error("Failed to fetch form data:", error);
    } finally {
      setIsTransitioning(false);
    }
  };

  //Check if the formIds array has any form data and if the form data has been fetched
  useEffect(() => {
    if (formIds.length > currentFormIndex && !formDataFetched) {
      fetchFormData();
    }
  }, [currentFormIndex, formIds, formDataFetched]);

  //Function to map the questions from the form data
  const mapQuestions = (questionData) => {
    return questionData
      .sort((a, b) => a.order - b.order)
      .map((question) => ({
        id: question._id,
        questionText: question.questionText,
        type: question.type,
        order: question.order,
        options: question.options
          ? question.options.map((option, index) => ({
              value: option.value,
              text: option.text,
              jump: option.jump,
              jumpTo: option.jumpTo,
              _id: option._id,
            }))
          : [],
        selectedOption: [],
        selectedOptionId: [],
        textAnswer: "",
      }));
  };

  //Function to handle the text change
  const handleOptionSelect = (option, currentQuestion) => {
    const optionValue = option.value;
    const optionId = option._id;
    const check1 = currentQuestion.selectedOption.includes(option.value);
    const check2 = currentQuestion.selectedOptionId.includes(option._id);
    const isOptionSelected = currentQuestion.selectedOptionId.includes(optionId);

    const updatedQuestions = form.questions.map((question) => {
      if (question.id === currentQuestion.id) {
        if (currentQuestion.type === "single-choice") {
          // For single-choice, set selected option directly
          return {
            ...question,
            selectedOptionId: isOptionSelected ? [] : [optionId],
            selectedOption: isOptionSelected ? [] : [option.value],
          };
        } else if (currentQuestion.type === "multiple-choice") {
          // For multiple-choice, toggle the selected option
          let updatedSelectedOptions = [...question.selectedOptionId];
          let updatedSelectedOptionValues = [...question.selectedOption];

          const optionIndexById = updatedSelectedOptions.indexOf(optionId);

          if (isOptionSelected) {
            // Remove the optionId and corresponding value if the option is already selected
            updatedSelectedOptions.splice(optionIndexById, 1);
            updatedSelectedOptionValues.splice(optionIndexById, 1);
          } else {
            // Add the optionId and value if the option is not yet selected
            updatedSelectedOptions.push(optionId);
            updatedSelectedOptionValues.push(option.value);
          }

          return {
            ...question,
            selectedOptionId: updatedSelectedOptions,
            selectedOption: updatedSelectedOptionValues,
          };
        }
      }
      return question;
    });

    setForm((prevForm) => ({
      ...prevForm,
      questions: updatedQuestions,
    }));
  };

  const handleTextChange = (e, currentQuestion) => {
    const text = e.target.value;
    const updatedQuestions = form.questions.map((question) => {
      if (question.id === currentQuestion.id) {
        return {
          ...question,
          textAnswer: text,
        };
      }
      return question;
    });

    setForm((prevForm) => ({
      ...prevForm,
      questions: updatedQuestions,
    }));
    adjustTextAreaHeight();
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isRecording) {
      handleStop();
    }
  };

  // Function to update assessment progress
  const updateAssessmentProgress = async (aid, progress) => {
    try {
      const { data: updatedAssessmentResponse } = await axiosInstance.put(
        `/api/forms/assessment/${aid}`,
        {
          progress,
        }
      );
      console.log({ updatedAssessmentResponse });

      setAssessmentData(updatedAssessmentResponse);
      setCurrentFormIndex(updatedAssessmentResponse.progress.currentFormIndex);
      setCurrentQuestionIndex(updatedAssessmentResponse.progress.currentQuestionIndex);
      setResponseId(updatedAssessmentResponse.progress.responseId);
      setFormIds(updatedAssessmentResponse.formIds);

      return updatedAssessmentResponse;
    } catch (error) {
      console.error("Error updating assessment progress:", error);
      alert("Error updating assessment progress. Please try again.");
      throw error;
    }
  };

  //Function to handle the task mapping
  const handleTaskMapping = async (
    formId,
    questionIndex,
    selectedOptionValue,
    assessmentProgress
  ) => {
    const jumpToValue = await taskMapping(
      formId,
      questionIndex,
      selectedOptionValue,
      assessmentProgress.responseId
    );
    if (jumpToValue !== null) {
      // If jumpToValue is 0, move to the first question of the next form
      if (jumpToValue === 0) {
        moveToNextForm(assessmentProgress);
      } else {
        // Find the question with the order equal to jumpToValue and navigate to it
        const targetQuestionIndex = form.questions.findIndex(
          (question) => question.order === jumpToValue
        );
        if (targetQuestionIndex !== -1) {
          setCurrentQuestionIndex(targetQuestionIndex);
          // Update the assessment progress in the database
          try {
            console.log("task mapping");
            await updateAssessmentProgress(aid, {
              ...assessmentProgress,
              currentQuestionIndex: targetQuestionIndex,
            });

            setCurrentQuestionIndex(targetQuestionIndex);
          } catch (error) {
            console.error("Error updating assessment progress:", error);
          }
        }
      }
      return true; // Indicate that we've handled the jump logic
    }
    return false; // Indicate that we should proceed with the existing logic
  };

  const submitResponse = async () => {
    try {
      const currentQuestion = form?.questions[currentQuestionIndex];
      let responsePayload = {
        formId: form.formId,
        respondentId: assessmentData?.patientId,
        assessmentId: assessmentData?._id,
        responses: [
          {
            questionId: currentQuestion.id,
            questionOrder: currentQuestion.order,
            questionText: currentQuestion.questionText,
            answer: {
              text: [],
              value: [],
            },
            metadata: { answeredAt: new Date().toISOString() },
          },
        ],
      };

      if (currentQuestion.type === "text") {
        responsePayload.responses[0].answer.text.push(currentQuestion.textAnswer);
        responsePayload.responses[0].answer.value.push(0);
      } else {
        currentQuestion.selectedOption.forEach((optionValue) => {
          const option = currentQuestion.options.find((option) => option.value === optionValue);
          if (option) {
            responsePayload.responses[0].answer.value.push(option.value);
            responsePayload.responses[0].answer.text.push(option.text);
          }
        });

        if (currentQuestion.type === "multiple-choice") {
          responsePayload.responses[0].answer.text = [];
          responsePayload.responses[0].answer.value = [];

          currentQuestion.selectedOptionId.forEach((selectedOptionId) => {
            const option = currentQuestion.options.find(
              (option) => option._id === selectedOptionId
            );
            if (option) {
              responsePayload.responses[0].answer.value.push(option.value);
              responsePayload.responses[0].answer.text.push(option.text);
            }
          });
        }
      }

      let currentResponse = null;

      try {
        if (assessmentData?.progress?.responseId) {
          currentResponse = (
            await axiosInstance.get(`/api/response/${assessmentData?.progress?.responseId}`)
          )?.data;
        } else {
          currentResponse = null;
        }
      } catch (err) {
        console.log("Error fetching current response:", err);
        currentResponse = null;
      }

      console.log("currentResponse: ", currentResponse);

      if (currentResponse && currentResponse?.formId === form?.formId) {
        console.log("Updating response");
        const { data: updateResponse } = await axiosInstance.put(
          `/api/response/${responseId}`,
          responsePayload.responses[0]
        );
        console.log({ updateResponse });
        return assessmentData.progress;
      } else {
        console.log("Creating response");
        const { data: postResponse } = await axiosInstance.post("/api/response", responsePayload);
        console.log({ postResponse });

        setResponseId(postResponse?._id);

        try {
          const { data: putAssessmentResponse } = await axiosInstance.put(
            `/api/forms/assessment/${aid}`,
            {
              $push: { responseIds: postResponse?._id },
              $set: {
                progress: {
                  ...assessmentData.progress,
                  responseId: postResponse?._id,
                },
              },
            }
          );

          console.log({ putAssessmentResponse });
          return putAssessmentResponse.progress;
        } catch (error) {
          console.error("Error updating responseIds:", error);
          throw error;
        }
      }
    } catch (error) {
      console.error("Error submitting response:", error);
      alert("Error submitting response. Please try again.");
      throw error;
    }
  };

  //Function to handle the final question or form submission
  const handleFinalSubmit = async (assessmentProgress) => {
    // try {
    //   await submitResponse();
    // } catch (error) {
    //   console.log("Error submitting response:", error);
    // }

    const currentQuestion = form.questions[currentQuestionIndex];
    const formId = form.formId;
    const questionIndex = currentQuestion.order;

    let selectedOptionValue = null;
    if (currentQuestion.type === "single-choice") {
      selectedOptionValue = currentQuestion.selectedOption[0];
    }

    const taskNum = getTaskNum(formId, questionIndex, selectedOptionValue);

    if (taskNum !== 0) {
      try {
        const { data: evaluateResponse } = await axiosInstance.get(
          `/api/response/evaluate/${assessmentProgress?.responseId}/${taskNum}`
        );
        await fetchAssessmentData();
        console.log({ evaluateResponse });
        const jumpToValue = evaluateResponse?.jumpTo;
        if (jumpToValue === 0) {
        }
      } catch (error) {
        console.error("Error completing task:", error);
        return;
      }
    }

    try {
      console.log("set assessment to completed");
      await updateAssessmentProgress(aid, {
        currentFormIndex: 0,
        currentQuestionIndex: 0,
        responseId: null,
        status: "completed",
      });

      setSubmissionSuccess(true);
      setFormIds([]);
      console.log("Form submission successful");

      navigate(`/formsubmit/${aid}`, { replace: true });
    } catch (error) {
      console.error("Error updating assessment status:", error);
    }
  };

  //Function to move to the next form
  const moveToNextForm = async (assessmentProgress) => {
    if (assessmentProgress.currentFormIndex >= formIds.length - 1) {
      handleFinalSubmit(assessmentProgress);
      return;
    }

    try {
      setIsTransitioning(true);
      const nextFormIndex = assessmentProgress.currentFormIndex + 1;

      console.log("Moving to next form");
      await updateAssessmentProgress(aid, {
        status: "pending",
        currentFormIndex: nextFormIndex,
        currentQuestionIndex: 0,
        responseId: null,
      });

      setCurrentFormIndex(nextFormIndex);
      setCurrentQuestionIndex(0);
      setResponseId(null);

      setFormDataFetched(false);
    } catch (error) {
      console.error("Error moving to next form:", error);
      alert("Error moving to next form. Please try again.");
    } finally {
      setIsTransitioning(false);
    }
  };

  // Function to handle the next question
  const handleNextQuestion = async () => {
    if (isTransitioning) return;

    if (isRecording) handleStop();

    let isJumpHandled = false;

    const formId = form.formId;
    const currentQuestion = form.questions[currentQuestionIndex];
    const qid = currentQuestion.id;
    const questionIndex = currentQuestion.order;

    let assessmentProgress = assessmentData.progress;

    let questionData;
    try {
      questionData = (await axiosInstance.get(`/api/question/id/${qid}`))?.data;
      console.log({ questionData });
    } catch (error) {
      console.error("Error fetching question data:", error);
      alert("Error fetching question data. Please refresh the page.");
      return;
    }

    let selectedOptionValue = null;
    if (currentQuestion.type === "single-choice") {
      const actualSelectedOption = currentQuestion.selectedOption[0];
      const optionIndex = questionData?.options.findIndex(
        (option) => option.value === actualSelectedOption
      );
      selectedOptionValue = optionIndex + 1;
    }

    const getTaskNumber = getTaskNum(formId, questionIndex, selectedOptionValue);

    console.log("Task number:", getTaskNumber);

    if (getTaskNumber !== 0) {
      try {
        assessmentProgress = await submitResponse();
      } catch (error) {
        console.error("Error submitting response:", error);
        return;
      }

      try {
        isJumpHandled = await handleTaskMapping(
          formId,
          questionIndex,
          selectedOptionValue,
          assessmentProgress
        );
      } catch (error) {
        console.error("Error handling task mapping:", error);
        return;
      }

      if (isJumpHandled) return;
    }

    const isLastForm = currentFormIndex === formIds.length - 1;
    const isLastQuestionByJump = currentQuestion.options.find(
      (option) =>
        option.jump && currentQuestion.selectedOption.includes(option.value) && option.jumpTo === 0
    );
    const isActualLastQuestion = currentQuestionIndex === form.questions.length - 1;

    if (!isLastForm || (!isLastQuestionByJump && !isActualLastQuestion)) {
      try {
        assessmentProgress = await submitResponse();
      } catch (error) {
        console.error("Error submitting response:", error);
        return;
      }
    }

    if (currentQuestion.type === "text") {
      const nextIndex = currentQuestionIndex + 1;
      if (nextIndex < form.questions.length) {
        try {
          console.log("handling test question");
          await updateAssessmentProgress(aid, {
            ...assessmentProgress,
            currentQuestionIndex: nextIndex,
          });

          setCurrentQuestionIndex(nextIndex);
        } catch (error) {
          console.error("Error updating assessment progress:", error);
        }
      } else {
        moveToNextForm(assessmentProgress);
      }
      return;
    }

    const selectedOption = currentQuestion.options.find((option) =>
      currentQuestion.selectedOption.includes(option.value)
    );

    if (selectedOption && selectedOption.jump) {
      if (selectedOption.jumpTo === 0) {
        moveToNextForm(assessmentProgress);
        return;
      } else {
        const jumpToQuestionIndex = form.questions.findIndex(
          (question) => question.order === selectedOption.jumpTo
        );
        if (jumpToQuestionIndex !== -1) {
          setCurrentQuestionIndex(jumpToQuestionIndex);
          try {
            console.log("handling jump question");
            await updateAssessmentProgress(aid, {
              ...assessmentProgress,
              currentQuestionIndex: jumpToQuestionIndex,
            });
          } catch (error) {
            console.error("Error updating assessment progress:", error);
          }
          return;
        }
      }
    }

    const nextIndex = currentQuestionIndex + 1;
    const nextFormIndex =
      nextIndex === form.questions.length ? currentFormIndex + 1 : currentFormIndex; // Check if next question is the first question of the next form

    if (nextIndex < form.questions.length) {
      setCurrentQuestionIndex(nextIndex);
    } else {
      moveToNextForm(assessmentProgress);
    }

    try {
      const newCurrentQuestionIndex = nextIndex === form.questions.length ? 0 : nextIndex; // Reset question index if moved to next form
      console.log("handling next question", assessmentProgress);
      await updateAssessmentProgress(aid, {
        ...assessmentProgress,
        currentFormIndex: nextFormIndex,
        currentQuestionIndex: newCurrentQuestionIndex,
      });

      setCurrentFormIndex(nextFormIndex);
      setCurrentQuestionIndex(newCurrentQuestionIndex);
    } catch (error) {
      console.error("Error updating assessment progress:", error);
    }
  };

  if (!form || isTransitioning) {
    return <div>Loading...</div>;
  }

  const currentQuestion = form.questions[currentQuestionIndex];

  const isNextQuestionButtonEnabled = () => {
    const currentQuestion = form.questions[currentQuestionIndex];
    if (currentQuestion.type === "text") {
      return currentQuestion.textAnswer.trim() !== "";
    } else {
      return currentQuestion.selectedOption.length > 0;
    }
  };

  return (
    <>
      <Container className="form-page-container">
        {currentQuestion && (
          <Form onSubmit={handleFormSubmit}>
            <h3>{currentQuestion.questionText}</h3>
            {currentQuestion.type === "text" ? (
              <>
                <div style={{ alignItems: "center", justifyContent: "space-between" }}>
                  <div className="form-record-container">
                    <textarea
                      ref={textAreaRef}
                      className="form-record-input"
                      type="text"
                      placeholder="Enter response"
                      value={currentQuestion.textAnswer}
                      onChange={(e) => handleTextChange(e, currentQuestion)}
                      rows={1}
                      onInput={(e) => {
                        e.target.style.height = "auto";
                        e.target.style.height = `${e.target.scrollHeight}px`;
                      }}
                    />
                    <button
                      className="form-record-button"
                      onClick={isRecording ? handleStop : handleRecording}
                      style={{
                        backgroundColor: isRecording ? "#FF4F52" : "grey",
                      }}
                    >
                      <FontAwesomeIcon icon={faMicrophone} />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              currentQuestion.options.map((option, index) => (
                <Form.Check
                  type={currentQuestion.type === "single-choice" ? "radio" : "checkbox"}
                  id={`${currentQuestion.id}-${index}`}
                  key={`${currentQuestion.id}-${index}`}
                  name={
                    currentQuestion.type === "single-choice"
                      ? `question-${currentQuestion.id}`
                      : undefined
                  }
                  label={option.text}
                  onChange={() => handleOptionSelect(option, currentQuestion)}
                  checked={currentQuestion.selectedOptionId.includes(option._id)}
                />
              ))
            )}
            <div className="form-navigation-buttons">
              <Button
                onClick={handleNextQuestion}
                disabled={!isNextQuestionButtonEnabled() || submissionSuccess}
              >
                {currentQuestionIndex < form.questions.length - 1 ||
                currentFormIndex < formIds.length - 1
                  ? "Next Question"
                  : "Submit"}
              </Button>
            </div>
          </Form>
        )}
        <div className="status-bar-container">
          <div className="status-bar" style={{ width: `${progressPercentage}%` }}></div> <br />
          {/* <div className="progress-percentage">{`Progress: ${progressPercentage.toFixed(0)}%`}</div> */}
        </div>
      </Container>
      <div className="assessment-duration-notice">
        Please continue with the assessment, which is expected to take 40-60 minutes to complete.
        <br />
        The progress bar will update upon completion of each segment.
      </div>

      <div
        className="save-and-exit-button"
        style={{ visibility: isButtonVisible ? "visible" : "button" }}
      >
        <Button
          style={{ backgroundColor: "white", color: "black" }}
          className="center-button"
          onClick={() => navigate(`/saveAndExit/${aid}`)}
        >
          Save and Exit
        </Button>
      </div>
    </>
  );
};

export default Formfile;
