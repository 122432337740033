import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Formfile from './component/form/form.js';
import Login from './component/login/login.js';
import FormSubmit from './component/form/formSubmit.js';
import AssessmentStatus from './component/assessmentStatus/assessmentStatus';
 import RedirectComponent from './component/redirectingComponent/redirectingComponent.js';
import EndUserDetailsForm from './component/form/detailsForm/endUserDetailsForm.js';
import PatientDetailsForm from './component/form/detailsForm/patientDetailsForm.js';
import SaveAndExit from './component/saveAndExit/saveAndExit.js';

function App() {
  return (
    <Router>
    <Routes>
     <Route path="/assessment/:aid" element={<RedirectComponent />} />
      <Route path="/login/:aid" element={<Login />} />
      <Route path="/form/:aid" element={<Formfile />} />
      <Route path="/formsubmit/:aid" element={<FormSubmit />} />
      <Route path="/assessmentStatus/:aid" element={<AssessmentStatus />} />
      <Route path="/endUserDetails/:aid" element={<EndUserDetailsForm />} />
      <Route path="/patientDetails/:aid" element={<PatientDetailsForm />} />
      <Route path="/saveAndExit/:aid" element={<SaveAndExit />} />
    </Routes>
  </Router>
  );
}

export default App;