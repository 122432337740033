import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../api/axiosService";
import './login.css';

const Login = () => {
  const [enteredPin, setEnteredPin] = useState("");
  const [pinError, setPinError] = useState(false);
  const [assessmentData, setAssessmentData] = useState(null);
  const navigate = useNavigate();
  const { aid } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axiosInstance.get(`/api/forms/assessment/${aid}`);
        setAssessmentData(result.data);
        if (result.data.patientId.type === "end-user") {
          navigate(`/assessment/${aid}`);
        }
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, [aid]);

  const handleInputChange = (event) => {
    setEnteredPin(event.target.value);
    setPinError(false);
  };

  const handleButtonClick = async () => {
    if (!enteredPin.trim()) {
      setPinError(true);
      setEnteredPin("");
      return;
    }

    try {
      const response = await axiosInstance.post('/api/password/verify-password', {
        enteredPin,
        aid
      });

      if (response.data.valid) {
        if (assessmentData.userDetails) {
          navigate(`/form/${aid}`);
        } else {
          navigate(`/patientDetails/${aid}`);
        }
      } else {
        setPinError("Invalid password");
        setEnteredPin("");
      }
    } catch (error) {
      setPinError(error.response ? error.response.data.message : "Authentication failed. Please try again.");
      setEnteredPin("");
    }
  };

  if (!assessmentData) return <div>Loading...</div>; // Handle loading state

  const { firstName, lastName } = assessmentData.patientId || {};

  return (
    <div className="home-page-container">
      <h1 className="home-page-title">Hello, {firstName} {lastName}!</h1>
      <h3 className="home-page-subtitle">Please enter the password you received in your email to get started</h3>
      <div className="input-button-section">
        <input
          type="password"
          value={enteredPin}
          onChange={handleInputChange}
          placeholder="Enter your password"
          className="pin-input-field"
        />
        <button
          onClick={handleButtonClick}
          className="start-assessment-btn"
        >
          Start Assessment
        </button>
        {pinError && <div className="pin-error-msg">Incorrect password! Please try again</div>}
      </div>
    </div>
  );
}

export default Login;
