import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../api/axiosService';

const RedirectingComponent = () => {
  const [assessmentData, setAssessmentData] = useState(null);
  const navigate = useNavigate();
  const {aid} = useParams();

  const fetchAssessmentData = async () => {
    try {
      const response = await axiosInstance.get(`/api/forms/assessment/${aid}`);
      const data = response.data;
     
       if ((data && data.message) ||  (data.progress.status === 'completed')) {
        navigate(`/assessmentStatus/${aid}`);
      } else {
        setAssessmentData(data);
      }
    } catch (error) {
      console.error('Error fetching assessment data:', error);
      navigate(`/assessmentStatus/${aid}`);
    }
  };

  useEffect(() => {
    fetchAssessmentData();
  }, [aid]);

  useEffect(() => {
    if (assessmentData) {
      if (assessmentData.patientId && typeof assessmentData.patientId === 'object') {
        if (assessmentData.patientId.type === 'patient') {
          navigate(`/login/${aid}`);
        } else if (assessmentData.patientId.type === 'end-user') {
          if (assessmentData.userDetails) {
            navigate(`/form/${aid}`);
          } else {
            navigate(`/endUserDetails/${aid}`);
          }
        }
      }
    }
  }, [assessmentData, navigate, aid]);

  return null;
};

export default RedirectingComponent;
