import React, { useState, useEffect } from 'react';
import axiosInstance from '../../api/axiosService';
import './assessmentStatus.css';
import { useParams } from 'react-router-dom';

const AssessmentStatus = () => {
  const { aid } = useParams();
  const [assessmentData, setAssessmentData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axiosInstance.get(`/api/forms/assessment/${aid}`);
      setAssessmentData(result.data);
    };

    fetchData();
  }, [aid]); // Dependency array with `aid` to refetch if `aid` changes

  const getContent = () => {
    if (!assessmentData) {
      return {
        title: "Assessment Not Found",
        message: "No available assessment at this moment."
      };
    } else if (assessmentData.progress && assessmentData.progress.status === 'completed') {
      return {
        title: "Assessment Completed",
        message: "The assessment you are trying is already over."
      };
    } else {
      return {
        title: "Assessment In Progress",
        message: "The assessment you are trying is in progress."
      };
    }
  };

  const { title, message } = getContent();

  return (
    <div className="assessment-container">
      <h1>{title}</h1>
      <p>{message}</p>
    </div>
  );
};

export default AssessmentStatus;
