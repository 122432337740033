import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../api/axiosService';
import '../form/form.css';
import { Button } from 'react-bootstrap';

const SaveAndExit = () => {
 const { aid } = useParams();
 const [userType, setUserType] = useState('');

 useEffect(() => {
  const fetchUserType = async () => {
     try {
       const response = await axiosInstance.get(`/api/forms/assessment/${aid}`);
       setUserType(response.data.patientId.type); 
     } catch (error) {
       console.error('Error fetching user type:', error);
     }
  };
  
  fetchUserType();
 }, [aid]);

 const goToDashboard = () => {
  window.location.href = 'https://user-stage.cliniscripts.com/dashboard'; 
 };

 return (
    <div className="container">
      <h1>Progress Saved</h1>
      <h2>Your session has been successfully saved.</h2>
      <h3>You can return at any time to continue.</h3>
      <h3>It is now safe to close this page.</h3>
      {userType === 'end-user' && (
        <Button onClick={goToDashboard}
        style={{marginTop: '20px', width: '200px', height: '50px', fontSize: '20px'}}
        >Go to Dashboard</Button>
      )}
    </div>
 );
}

export default SaveAndExit;
